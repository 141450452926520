* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.25;
}

html {
  font-size: 1rem;
}

body {
  margin: 0;
  padding: 0;
  background: #F8F9FB;
  color: #191919;
  font-family: Inter, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}
