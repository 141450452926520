.fs-alert-box {
  padding: 16px;
  width: 100%;
  max-width: 460px;

  &__image {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  &__title {
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    font-size: 48px;
    margin: 16px 0 0 0;

    & strong {
      font-weight: 900;
    }
  }

  &__description {
    text-align: center;
    margin: 16px 0 0 0;
    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    font-size: 18px;
    max-width: 400px;
  }

  &__link-button-wrapper {
    margin: 16px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link-button {
    display: inline-block;
    padding: 16px 80px;
    background-color: #7038A7;
    color: #FFF;
    border-radius: 8px;
    text-decoration: none;
    transition: transform .3s ease-in-out, background-color .3s linear;

    &:hover {
      transform: scale(1.1);
      background-color: #854BBE;
    }
  }
}
